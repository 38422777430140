
const OMD_PATIENT_TAB_ID = 'patient-tab'
const OMD_PATIENT_TAB_CONTENT_ID = 'patient-content'
const OMD_PRODUCTS_TAB_ID = 'products-tab'
const OMD_PRODUCTS_TAB_CONTENT_ID = 'products-content'
const OMD_OD_TAB_ID = 'od-tab'
const OMD_OD_TAB_CONTENT_ID = 'od-content'
const ACTIVE_TAB_CLASS = ''
const INACTIVE_TAB_CLASS = ''

class Invoices {
  omd_activate_patient_tab() {
    this.activate_tab(OMD_PATIENT_TAB_ID)
    this.deactivate_tab(OMD_PRODUCTS_TAB_ID)
    this.deactivate_tab(OMD_OD_TAB_ID);
  }

  omd_activate_products_tab() {
    this.deactivate_tab(OMD_PATIENT_TAB_ID)
    this.activate_tab(OMD_PRODUCTS_TAB_ID)
    this.deactivate_tab(OMD_OD_TAB_ID);
  }

  omd_activate_od_tab() {
    this.deactivate_tab(OMD_PATIENT_TAB_ID)
    this.deactivate_tab(OMD_PRODUCTS_TAB_ID)
    this.activate_tab(OMD_OD_TAB_ID);
  }


  omd_render_tab(key, html) {
    let tab_id = this.omd_content_tab_id_from_key(key)
    this.render_tab(tab_id, html)
  }

  render_tab(tab_id, html) {
    let tab = this.omd_content_pane_for(tab_id)
    tab.html(html)
  }
  omd_render_patient_tab(html) {
    this.render_tab(OMD_PATIENT_TAB_CONTENT_ID, html)
  }

  omd_render_products_tab(html) {
    this.render_tab(OMD_PRODUCTS_TAB_CONTENT_ID, html)
  }

  omd_render_product_tab(html) {
    this.render_tab(OMD_OD_TAB_CONTENT_ID, html)
  }

  omd_tab_id_from_key (key) {
    switch(key) {
        case 'patient':
            return OMD_PATIENT_TAB_ID
        case 'products':
            return OMD_PRODUCTS_TAB_ID
        case 'od':
            return OMD_OD_TAB_ID
        default:
            console.error('Unknow Tab Key ' + key)
            return 'deadbeef-this-is-an-error'
    }
  }
  omd_content_tab_id_from_key (key) {
    switch(key) {
        case 'patient':
            return OMD_PATIENT_TAB_CONTENT_ID
        case 'products':
            return OMD_PRODUCTS_TAB_CONTENT_ID
        case 'od':
            return OMD_OD_TAB_CONTENT_ID
        default:
            console.error('Unknow Tab Content Key ' + key)
            return 'deadbeef-this-is-an-error'
    }
  }

  omd_activate_tab (key) {
      this.activate_tab(this.omd_tab_id_from_key(key))
  }

  omd_deactivate_tab (key) {
      this.deactivate_tab(this.omd_tab_id_from_key(key))
  }

  activate_tab(tab_id) {
    let tab = this.omd_tab_for(tab_id)
    tab.removeClass(INACTIVE_TAB_CLASS)
    tab.addClass(ACTIVE_TAB_CLASS)
    tab.tab('show')
  }

  deactivate_tab(tab_id) {
    let tab = this.omd_tab_for(tab_id)
    tab.removeClass(ACTIVE_TAB_CLASS)
    tab.addClass(INACTIVE_TAB_CLASS)
  }

  omd_tab_for(elem_id) {
    let query ="#"+elem_id
    let elem = $(query)
    return elem
  }

  omd_content_pane_for(elem_id) {
    let query = "#" + elem_id
    let elem = $(query)
    return elem
  }
}

export default new Invoices()

$.expandReceiptOptions = function () {
  $("#receipt-options-container").css("display", "block");
  $("#receipt-options-container").attr("aria-expanded","true");
}

$.collapseReceiptOptions = function(){
  $("#receipt-options-container").css("display", "none")
  $("#receipt-options-container").attr("aria-expanded","false");
}

$.enable_receipt_btn = function(){
  $("#send-receipt-btn").removeClass('disabled');
  $("#send-receipt-btn").prop('disabled', false);
  $("#send-receipt-btn").attr("value", "Send");
  $("#send-receipt-btn").addClass('hoverable-border');
  $("#send-receipt-btn").css('cursor', 'pointer');
}

$.disable_receipt_btn = function(){
  $("#send-receipt-btn").addClass('disabled');
  $("#send-receipt-btn").prop('disabled', true);
  $("#send-receipt-btn").attr("value", "Sent");
  $("#send-receipt-btn").removeClass('hoverable-border');
  $("#send-receipt-btn").css('cursor', 'default');
}

$.check_receipt_email_presence = function(){
  if($("#email-field").val().length == 0){
    $("#send-receipt-btn").addClass('disabled');
    $("#send-receipt-btn").prop('disabled', true);
    $("#send-receipt-btn").removeClass('hoverable-border');
    $("#send-receipt-btn").css('cursor', 'default');
  }
}

$.check_invoice_email_presence = function(){
  if($("#invoice_patient_email").val().length == 0){
    $("#send-invoice-btn").addClass('disabled');
    $("#send-invoice-btn").prop('disabled', true);
    $("#send-invoice-btn").removeClass('hoverable-border');
    $("#send-invoice-btn").css('cursor', 'default');
  }else{
    $("#send-invoice-btn").removeClass('disabled');
    $("#send-invoice-btn").prop('disabled', false);
    $("#send-invoice-btn").addClass('hoverable-border');
    $("#send-invoice-btn").css('cursor', 'pointer');
  }
}

$.update_receipt_btn = function(last_recipient){
  if (last_recipient){
    $("#send-receipt-btn").val("Resend");
    $("#send-receipt-btn").attr("data-disable-with", "Resend");
  }
}

$.check_for_paid_refresh = function(self, elem, path) {
  self.disabled = "true";
  self.elem = elem;
  new cofiPaidInvoiceRefreshUpdater(path, function(complete, data) {
      if(complete) {
        self.disabled = "";
        for (var key in data) {
          if(data[key] === true) {
            $('#refund-refresh-modal').modal('show');
            return;
          }
        }
        self.elem.modal('show');
      } else {
        // there has been an error so show the refresh modal
        $('#refund-refresh-modal').modal('show');
      }
  }.bind(self));
}